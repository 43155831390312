// Store
import { setBuildingAddress } from "@data";
import { round } from "@utils";
import { getGoogleMapsAPI } from "./getGoogleMapsApi";

// getGeocode
export const getGeocode = async (lat,lng) => {
  const googleMaps = await getGoogleMapsAPI();
  const geocoder = new googleMaps.Geocoder();

  try {
    const response = await new Promise((resolve, reject) => {
      geocoder.geocode({ location: { lat, lng } }, (results, status) => {
        if (status === "OK") {
          resolve(results);
        } else {
          reject(new Error(`Geocoding failed: ${status}`));
        }
      });
    });

    const result = response[0];

    const getAddressComponent = (type) => {
      return result.address_components.find(comp => comp.types.includes(type));
    };

    // Set resolved address
    setBuildingAddress(`${getAddressComponent("formatted_address")} (${round(lat,5)}, ${round(lng,5)})`);
  } catch (error) {
    console.error("Geocoding error:", error);
    return null;
  }
};
